import React, { useCallback } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';
import { isEqual } from "lodash";

import { fonts, transitionDefault, fontSmoothing, dimensions } from "~/styles";
import { messageSet, words } from '~/recoil';
import * as utils from "~/utils";
import { TMessage } from '~/content/types';


const StyledGenerateButton = styled.button`
  font-family: ${fonts.headers};
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  background: none;
  color: white;
  position: absolute;
  left: 0;
  top: -50px;
  outline: none;
  white-space: nowrap;
  padding-left: 50px;
  cursor: pointer;
  line-height: 50px;
  ${fontSmoothing};
  overflow: hidden;
  user-select: none;

  ${transitionDefault};

  &:hover {
    /* transform: translateY(-1px); */
  }
  &:active {
    transform: translateY(1px);
  }

  img {
    position: absolute;
    left: -10px;
    top: 2px;
    width: 60px;
    height: 60px;
    display: inline-block;
    max-height: none;
  }
`;

export const GenerateRandomMessage: React.FC<{}> = () => {
  const setMessageState = useSetRecoilState(messageSet);
  const currentWords = useRecoilValue(words);

  const handleClick = useCallback(() => {
    let message: TMessage;

    // always fresh message
    do {
      message = utils.getRandomPreparedMessage()
    } while ( isEqual(message.words, currentWords.map(word => word.text)) )

    setMessageState(message);
  }, [currentWords]);

  return (
    <StyledGenerateButton onClick={handleClick}>
      <img src="/images/dice.svg" alt="random" />
      { utils.t('Generate random message') }
    </StyledGenerateButton>
  )
};