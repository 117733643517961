import React, { useState, PointerEvent, memo, useRef, useEffect, useCallback } from 'react';
import styled from "@emotion/styled"; 
import { motion, PanInfo, BoxDelta } from "framer-motion";

import { animation, colors, transitionDefault, absoluteFill, css } from '~/styles';
import { TWord } from '~/content/types/app';

import { useRecoilState } from 'recoil';
import {
  dropArea as dropAreaState, 
  takeArea as takeAreaState
} from "~/recoil";
import * as utils from "~/utils";
import { Word } from "~/components/common/Word";

const TAP_MAX_TIME_MS = 200;


const StyledWordCont = styled(motion.span)<StylesProps>`
  display: inline-block;
  position: relative;
  width: 74px;
  height: 38px;
  padding: ${props => props.isSelected ? '0' : '3px 5px'} ;
  margin-bottom: 8px;
  box-sizing: content-box;
`

const StyledDragWord = styled(StyledWordCont)<StylesProps>`
  display: block;
  padding: 0;
  margin: 0;

  will-change: transform;
  position: absolute;
  top: 3px;
  left: 5px;
  

  user-select: none;
  z-index: ${ props => props.isDragging ? 10 : 1 };
  position: ${ props => props.isDragging ? 'fixed' : 'absolute' };
  
  cursor: move; /* fallback */
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  img {
    pointer-events: none;
    user-select: none;
    border-radius: 20px;
    border: transparent solid 2px;

    transition: all ${animation.fast}ms ease;
    transition-property: border-color, transform;

    /* border-color: ${ props => props.isDragging ? props.isSelected ? colors.red : colors.black : 'transparent'}; */
    border-color: ${ props => props.isDraggingOver ? props.isSelected ? colors.red : props.cantDrop ? colors.red : colors.black : 'transparent'};
  }

  &:after { /* cantDrop indicator */
    position: absolute;
    content: '×';
    font-size: 10px;
    height: 15px;
    line-height: 15px;
    width: 15px;
    text-align: center;
    top: -8px;
    right: 0;
    border-radius: 50%;
    background-color: ${colors.red};

    ${transitionDefault};
    transform: translateZ(0);
    opacity: 0;
  }

  ${ props => props.isDraggingOver && props.cantDrop ? css`
    &:after {
      opacity: 1;
    }
  `: null};
`

const StyledGhostWord = styled(StyledDragWord)`
  ${absoluteFill};
  opacity: 0.7;
  top: 3px;
  left: 5px;
  pointer-events: none;
  cursor: default;
  width: 83px;
`

const StyledWord = styled(Word)<StylesProps>`
  ${transitionDefault};
  transform: ${ props => props.isDragging ? 'rotate(-10deg)' : 'none' };
`

interface StylesProps {
  isDragging?: boolean;
  isSelected?: boolean;
  isDraggingOver?: boolean;
  cantDrop?: boolean;
}
interface Props {
  word: TWord;
  showGhost?: boolean;
  onDrag?: (word: TWord, info: PanInfo) => void;
  onDrop?: (word: TWord, info: PanInfo) => void;
  onVieportBoxUpdate?: (index: number, delta: BoxDelta) => void;
  onTapQuick?: (word: TWord) => void;
  cantAddMore?: boolean;
  index?: number;

  updateVer?: number;
}

export const DraggableWord: React.FC<Props> = memo(props => {
  const ref = useRef<HTMLSpanElement>(null);
  // states for drag visualisation
  const [isDragging, setDragging] = useState(false);
  const [isDraggingOverArea, setDraggingOverArea] = useState(false);
  const [tapStartTime, setTapStartTime] = useState(0);

  const [dropArea, ] = useRecoilState(dropAreaState);
  const [takeArea, ] = useRecoilState(takeAreaState);

  const checkDraggingOver = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if( 
      props.word.isSelected && utils.isWithin(info.point, takeArea) ||
     !props.word.isSelected && utils.isWithin(info.point, dropArea)
   ) {
     setDraggingOverArea( true );
   }
   else {
     setDraggingOverArea( false );
   }
  }

  const onDrag = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    checkDraggingOver(event, info);
    props.onDrag ? props.onDrag(props.word, info) : null;
  }
  const onDragStart = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    setDragging(true);
  }
  const onDragEnd = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    setDragging(false);
    setDraggingOverArea( false );
    props.onDrop ? props.onDrop(props.word, info) : null;
  }

  const onTapStart = 
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => 
      setTapStartTime( event.timeStamp );

  const onTap = 
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      if( tapStartTime && event.timeStamp - tapStartTime < TAP_MAX_TIME_MS ) {
        props.onTapQuick ? props.onTapQuick(props.word) : null;
      }
      setTapStartTime( 0 );
    }

  // const onVieportBoxUpdate = useCallback((index: number, delta: BoxDelta) => {
  //   // console.log(delta.x, initialPosition.x);
  // }, [initialPosition]);

  const interactiveProps = {
    drag: true,
    dragConstraints: { top: 0, bottom: 0, left: 0, right: 0 },
    dragElastic: 1,
    dragTransition: { bounceStiffness: 600, bounceDamping: 20 },
    onDrag: onDrag,
    onDragStart: onDragStart,
    onDragEnd: onDragEnd,

    onTap: onTap,
    onTapStart: onTapStart,

    whileTap: {
      scale: 1.12,
      zIndex: 2
    }
  };

  return (
    <StyledWordCont
      isSelected={props.word.isSelected}
      ref={ref}
    >
      {
        props.showGhost && 
        <StyledGhostWord>
          <Word word={props.word} key={0} />
        </StyledGhostWord>
      }
      {
        <StyledDragWord
          key={`${props.word.text}${props.word.key}`}
          whileHover={{
            scale: 1.05
          }}
          layout
          // layoutId={`${props.word.text}${props.word.key}`}
          transition={{ type: 'tween', duration: 0 }}

          {...interactiveProps}
          
          isDragging={isDragging}
          isSelected={props.word.isSelected || false}
          isDraggingOver={isDraggingOverArea}
          cantDrop={props.cantAddMore}
          // onViewportBoxUpdate={(_viewportBox, delta) => {
          //   isDragging && props.onVieportBoxUpdate && onVieportBoxUpdate(props.index || -1, delta);
          // }}
        >
          <StyledWord word={props.word} key={1} isDragging={isDragging} />
        </StyledDragWord>
      }
    </StyledWordCont>
  )
}, (prev, next) => {
  return prev.updateVer === next.updateVer
  
    // prev.onDrag === next.onDrag &&
    // prev.onDrop === next.onDrop &&
    // prev.onTapQuick === next.onTapQuick &&
    // prev.cantAddMore === next.cantAddMore
    
  ;
});