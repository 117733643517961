import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import { navigate } from "gatsby";
import styled from "@emotion/styled"; 
import { PanInfo, AnimateSharedLayout } from "framer-motion";
import { useRecoilValue, useSetRecoilState } from "recoil";

import * as utils from "~/utils";
import { css, fluidRange, respondFrom, respondTo, breakpoints, } from '~/styles';
import { TWord } from "~/content/types";
import { words as wordsState,
  wordAdd, wordRemove, wordDrop
} from "~/recoil";
import { Button, PageSection } from "~/components/common";
import { DropArea } from "~/components/index/DropArea";
import { TakeArea } from "~/components/index/TakeArea";
import { GenerateRandomMessage } from "./GenerateRandomMessage";


const localMobileBreakpoint = breakpoints.md;

/** styles */
const StyledSection = styled(PageSection)`
  ${fluidRange('paddingBottom', '50px', '90px')}
`

const StyledCreatorCont = styled.div`

  ${respondFrom(
    localMobileBreakpoint,
    css`
      display: flex;
      flex-flow: row;
      justify-content: space-between;
    `
  )}
`

interface StyledFormAreaProps {
  isDraggedOver: boolean;
  isDropWordDragged: boolean;
}
const StyledFormArea = styled.div<StyledFormAreaProps>`
  position: relative;
  z-index: ${props => props.isDropWordDragged ? 2 : 0};

  ${respondTo(
    localMobileBreakpoint,
    css`
      min-height: 150px;
      padding-bottom: 20px;
      margin-top: 60px;
    `
  )}
  ${respondFrom(
    localMobileBreakpoint,
    css`
      flex-basis: 38%;
      width: 38%;
    `
  )}
`

// TODO: words should be kept somewhere else (GraphQL)
const getWordsPool = () => {
  const wordsPoolArray =  [
    "של",
    "על",
    "ה",
    "לא",
    "עם",
    "זו",
    "ש",
    "כל",
    "ו",
    "ב",
    "יש",
    "זה",
    "ליד",
    "תמיד", "אני", "הם", "להיות", "יופי", "ביותר", "גדול", "אמיץ", "לבנות", "על ידי", "רגוע", "יכול", "לדאוג", "לשנות", "בחירה", "לבוא", "מגניב", "ליצור", "יום", "לעשות", "לחלום", "קל", "ליהנות", "משפחה", "להרגיש", "למצוא", "בשביל", "חופשי", "רענן", "חבר", "מלא", "כיף", "מצחיק", "עתיד", "רך", "טוב", "שמח", "לב", "תקווה", "חיבוק", "רעיון", "הוא", "הנאה", "לשמור", "נשיקה", "לתת", "חיים", "קטן", "לחיות", "להביט", "אהבה", "לי", "הכי", "שלי", "צריך", "נחמד", "אחד", "שלום", "יפה", "סיבה", "נכון", "לשתף", "צד", "פשוט", "חיוך", "כמה", "נשמה", "מיוחד", "כוכבים", "להישאר", "חזק", "מתוק", "לקחת", "קבוצה", "דבר", "לחשוב", "זמן", "ל", "אמון", "לנסות", "אנרגיות", "רוצה", "דרך", "אנחנו", "לקוות", "עבודה", "עולם", "אתה", "צעיר", "שלך", "אמת", "הטוב", "אוויר", "חָדָשׁ", "מתנה", "לְהַגִיעַ", "עצמי", "מאי", "היא", "עצמך", "קול", "למען", "אחד מ", "קצת", "דולק", "בפנים", "מרגיש", "חופש", "דאגה", "תחפושות", "תלבושת", "עליזות", "נדיבות", "מתנות", "עד לא ידע", "מלך", "מלכה", "משתה", "גיבור", "זולת", "אדיב", "צוהל", "לצד", "כולם", "צוות", "שמחה", "כוכב", "לראות", "לבחור", "ישר", "קשור", "משאלה", "עדין", "מקום", "חלום",
    "טובה",
    "מדליק"
  ];

  let ret: TWord[] = [];
  wordsPoolArray.forEach( (w,i) => {
    ret.push(
      utils.createWord({ text: w, key: 0, index: -1 })
    );
  })
  return ret;
}
const wordsPool = getWordsPool();
/** end words */


export interface MessageCreatorProps {}
const MessageCreator: React.FC<MessageCreatorProps> = () => {

  const words = useRecoilValue(wordsState);
  const addWordToStore = useSetRecoilState(wordAdd);
  const removeWordFromStore = useSetRecoilState(wordRemove);
  const wordDropStore = useSetRecoilState(wordDrop);
  const [isDraggedOverDrop, setIsDraggedOverDrop] = useState(false);  // user drags word from take area over drag area
  const [isDraggedOverTake, setIsDraggedOverTake] = useState(false);  // user drags word from drop area over take area 
  const [isDropWordDragged, setIsDropWordDragged] = useState(false);  // user drags any word from drop area?
  const [canAddMore, setCanAddMore] = useState(true);


  /** callbacks */
  const onWordDrag = useCallback((word: TWord, info: PanInfo) => {

    setIsDropWordDragged(word.isSelected || false);

    // word over opposite area?
    /*
    if( word.isSelected && utils.isWithin(info.point, takeArea) ) {
      !isDraggedOverTake && setIsDraggedOverTake(true);
    }
    else {
      isDraggedOverTake && setIsDraggedOverTake(false);
    }
    if( !word.isSelected && utils.isWithin(info.point, dropArea)) {
      !isDraggedOverDrop && setIsDraggedOverDrop(true);
    }
    else {
      isDraggedOverDrop && setIsDraggedOverDrop(false);
    }
    */
  }, []);

  const onWordDrop = useCallback((word: TWord, info: PanInfo) => {
    setIsDraggedOverDrop( false );
    setIsDraggedOverTake( false );

    wordDropStore({ word, info });
  }, []);

  const onWordTapQuick = useCallback((word: TWord) => {
    if( !word.isSelected ) {
      addWordToStore(word);
    }
    else {
      removeWordFromStore(word);
    }
  }, []);
  
  useEffect(() => {
    if( words.length >= utils.consts.MSG_WORDS_MAX && canAddMore ) {
      setCanAddMore(false);
    }
    else if( words.length < utils.consts.MSG_WORDS_MAX && !canAddMore ) {
      setCanAddMore(true);
    }
    // else do nothing
  }, [words])

  const pathAnimation = utils.getPath('animaton');
  const onClick = useCallback(() => { navigate(pathAnimation) }, []);

  return ( 
    <StyledSection>
      <h1>{ utils.t('Create your message') }</h1>
      <p>{ utils.t('Drag words into the box to create a sentence') }</p>

        <StyledCreatorCont>
        <AnimateSharedLayout>
          <TakeArea words={wordsPool}
            onWordDrag={onWordDrag} 
            onWordDrop={onWordDrop} 
            onWordTapQuick={onWordTapQuick}
            isDraggedOver={isDraggedOverTake} 
            cantAddMore={!canAddMore}
          />
          <StyledFormArea 
            isDropWordDragged={isDropWordDragged}
            isDraggedOver={isDraggedOverDrop}  
          >
            <GenerateRandomMessage />
            <DropArea 
              onWordDrag={onWordDrag} 
              onWordDrop={onWordDrop} 
              onWordTapQuick={onWordTapQuick}
              isDraggedOver={isDraggedOverDrop}  
            />

            <Button 
              disabled={ words.length < utils.consts.MSG_WORDS_MIN ? true : undefined }
              onClick={onClick}
              className="large"
            >
              { utils.t('Create your message CTA') }
            </Button>
          </StyledFormArea>
        </AnimateSharedLayout>
        </StyledCreatorCont>

    </StyledSection>
  );
}
 
export default MessageCreator;