import { useState, useCallback, useMemo, Dispatch, SetStateAction } from "react";
import move from "array-move";
import { clamp } from "@popmotion/popcorn";

import * as utils from "~/utils";
import { TWord } from "~/content/types";
import { Point2D } from "framer-motion";


const WORD_WIDTH = 74; // ouch!?
const WORD_HEIGHT = 40;

export function useDragReorder( initialState: TWord[] ): 
  [TWord[], Dispatch<SetStateAction<TWord[]>>, (index: number, delta: Point2D) => void] 
{

  const [order, setOrder] = useState<TWord[]>(initialState);

  const wordsPerLine = useMemo(() => {
    const dropArea = !utils.isSSR && document.getElementById('gm-drop-area');
    if( dropArea ) {
      const das = window.getComputedStyle(dropArea, null);
      const w = parseInt(das.getPropertyValue('width'));
      const p = parseFloat(das.paddingLeft) + parseFloat(das.paddingRight)
      const wordsPerLine = Math.floor( (w-p) / WORD_WIDTH );  // shakey! word width
      return wordsPerLine;
    }
    return 10;  // should never occur, but let's have a default
  }, [order]);

  const hasMultipleLines = useMemo(() => {
    return order.length > wordsPerLine;
  }, [order]);

  const onDrag = useCallback((index: number, offset: Point2D) => {
    if( !order.length ) return;

    let moveBy = Math.round(offset.x / WORD_WIDTH);

    if( hasMultipleLines ) {
      const moveY = Math.round(offset.y / WORD_HEIGHT);
      moveBy += moveY * wordsPerLine;
    }

    const limitedMoveX = clamp(-1*index, order.length-index-1, moveBy);
    if( moveBy && moveBy === limitedMoveX ) {
      setOrder( move(order, index, index + limitedMoveX));
    }
    else {
      setOrder( move(order, index, index));
    }
  }, [order]);

  return [ order, setOrder, onDrag ];
}
