import React, { memo, useState, useMemo, useRef, useEffect, useCallback } from 'react';
import styled from "@emotion/styled"; 
import { PanInfo, motion } from "framer-motion";
import { useRecoilState } from "recoil";
import LazyLoad from 'react-lazyload';

import * as utils from "~/utils";
import { css, respondFrom, respondTo, breakpoints, absoluteCenter, transitionDefault, absoluteFill } from '~/styles';
import { DraggableWord } from '~/components/index/DraggableWord';
import { TWord } from '~/content/types';
import { takeArea as takeAreaState } from "~/recoil";
import { truncate } from 'fs/promises';


const localMobileBreakpoint = breakpoints.md;
interface StyledTakeAreaProps {
  isDraggedOver: boolean;
}
const StyledTakeArea = styled(motion.div)<StyledTakeAreaProps>`
  margin: -5px -7px;
  position: relative;
  z-index: ${props => props.isDraggedOver ? -1 : 1};
  height: 260px;
  /* overflow: hidden; */
  padding: 0 25px;

  ${respondTo(
    localMobileBreakpoint,
    css`
      min-height: 150px;
      margin-bottom: 20px;
    `
  )}

  ${respondFrom(
    localMobileBreakpoint,
    css`
      flex-basis: 58%;
      width: 58%;  
      height: 260px;
      padding: 0 30px;
    `
  )}

`

const StyledSmoothers = styled.div`
  ${absoluteFill};
  pointer-events: none;

  /* left-right covers */
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 7px;
    z-index: 2;
  }
  &:before {
    left: 0;
    background-image: linear-gradient(to left, rgba(67, 171, 68, 0) 30%, #43ab44 95%);
  }
  &:after {
    right: 0;
    background-image: linear-gradient(to right, rgba(67, 171, 68, 0) 30%, #43ab44 95%);
  }
`

const StyledWordsContOuter = styled(motion.div)`
  max-height: 100%;
  position: relative;
  display: flex;
  flex-flow: row;
  overflow: scroll;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledWordsCont = styled(motion.div)`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  max-height: 100%;
  position: relative;

  > * {
    height: 50px;
    width: 84px;
  }
`

interface StyledArrowProps {
  isVisible: boolean;
}
const StyledArrow = styled.div<StyledArrowProps>`
  height: 100%;
  width: 100px;
  max-width: 5vw;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;
  
  user-select: none;  /* prevent blue bg when tapping on mobile */
  -webkit-tap-highlight-color: transparent;

  ${transitionDefault}
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};

  /* arrows */
  &:before {
    content: '';
    ${absoluteCenter};
    width: 25px;
    height: 15px;
    background-image: url('/images/arrow-down-white.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform-origin: center center;

    ${transitionDefault};
  }
`
const StyledArrowPrev = styled(StyledArrow)`
  /* background-image: linear-gradient(to left, rgba(67, 171, 68, 0) 30%, #43ab44 75%); */
  left: -10px;

  &:before {
    transform:  translateX(-10px) translateY(-50%) rotate(90deg);
  }

  &:hover:before {
    transform: translateX(-13px) translateY(-50%) rotate(90deg);
  }

  ${respondFrom(
    localMobileBreakpoint,
    css`
      left: -40px;
    `
  )}
`
const StyledArrowNext = styled(StyledArrow)`
  /* background-image: linear-gradient(to right, rgba(67, 171, 68, 0) 30%, #43ab44 75%); */
  right: -10px;

  &:before {
    transform: translateX(-15px) translateY(-50%) rotate(-90deg);
  }

  &:hover:before {
    transform: translateX(-12px) translateY(-50%) rotate(-90deg);
  }

  ${respondFrom(
    localMobileBreakpoint,
    css`
      right: -40px;
    `
  )}
`


interface Props {
  words: TWord[];
  isDraggedOver: boolean;
  cantAddMore: boolean;
  onWordDrag: (word: TWord, info: PanInfo) => void;
  onWordDrop: (word: TWord, info: PanInfo) => void;
  onWordTapQuick: (word: TWord) => void;
}
export const TakeArea: React.FC<Props> = memo((props) => {
  const refParent = useRef<HTMLDivElement>(null);
  const refScroll = useRef<HTMLDivElement>(null);
  const refItemsCont = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [, setTakeArea] = useRecoilState(takeAreaState);

  const updateDimensions = () => {
    if( refParent ) {
      const elem = refParent.current;
      setTakeArea( utils.getElemDimensions(elem) );
    }
  }

  const slide = ( backwards: boolean = false ) => {
    if( refScroll.current ) {
      const slideBy = refScroll.current.clientWidth * 0.8;

      refScroll.current.scrollBy({
        top: 0,
        left: backwards ? -slideBy : +slideBy,
        behavior: 'smooth'
      });
    }
  }

  const updateScrollState = () => {
    setScrollLeft( refScroll.current?.scrollLeft || 0 );
  }

  const maxScroll = useMemo(() => {
    if( !refItemsCont.current || !refScroll.current ) {
      return 0;
    }

    return refScroll.current?.scrollWidth - refScroll.current?.clientWidth;
  }, [refScroll.current, refItemsCont.current, !utils.isSSR ? window.innerWidth : undefined])

  useEffect(() => {
    if( refScroll.current ) {
      // scroll horizontally on mousewheel
      refScroll.current.addEventListener('wheel', function(e) {
        if( !!e.deltaY && !e.deltaX ) {
          e.preventDefault();
          refScroll.current && refScroll.current.scrollBy({
            top: 0,
            left: e.deltaY,
            behavior: 'smooth'
          });
        }
      });

      return function cleanup() {
        
      }
    }
  }, []);

  useEffect(() => {
    updateDimensions();
  }, []);

  // will run only once
  useEffect(() => { 
    if( utils.isSSR ) {
      return;
    }

    const listenerResize = 
      !utils.isSSR ? window.addEventListener('resize', 
        utils.debounce(updateDimensions, 100)
      ) : null;
    const listenerScroll = 
      !utils.isSSR ? refScroll.current?.addEventListener('scroll', 
      utils.debounce(updateScrollState, 50)
    ) : null;

    return function cleanup() {
      !!listenerResize && window.removeEventListener('resize', listenerResize);
      !!listenerScroll && refScroll.current?.removeEventListener('scroll', listenerScroll)
    }
  }, []);

  const onDrag = useCallback((word, info) => props.onWordDrag(word, info), [props.onWordDrag]);
  const onDrop = useCallback((word, info) => props.onWordDrop(word, info), [props.onWordDrop]);
  const onTapQuick = useCallback((word) => props.onWordTapQuick(word), [props.onWordTapQuick]);

  return <StyledTakeArea 
    // layout 
    // transition={{ type: 'spring' }}
    isDraggedOver={props.isDraggedOver}
    ref={refParent}
    >
      
    <StyledSmoothers />
    <StyledWordsContOuter ref={refScroll}>
      <StyledWordsCont ref={refItemsCont}>
        {
          props.words.map( (word, i ) => (
            <LazyLoad key={`${word.text}${word.key}`} once>
              <DraggableWord word={word} showGhost={true}
                onDrag={onDrag} 
                onDrop={onDrop} 
                onTapQuick={onTapQuick}
                cantAddMore={props.cantAddMore}
              ></DraggableWord>
            </LazyLoad>
          ))
        }
      </StyledWordsCont>
    </StyledWordsContOuter>

    { 
      <StyledArrowPrev isVisible={true}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => slide(true)}/> 
    }
    {
      <StyledArrowNext isVisible={true}
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => slide()} />
    }
  </StyledTakeArea>
});