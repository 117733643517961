import React, { useState } from "react";
import styled from "@emotion/styled"; 
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { motion, PanInfo, AnimatePresence, BoxDelta } from "framer-motion";
import { isEqual, findIndex } from "lodash";

import * as utils from "~/utils";
import { DraggableWord } from '~/components/index/DraggableWord';
import { absoluteFill, absoluteCenter, colors, animation, dimensions, breakpoints, respondFrom, css } from "~/styles";
import { words as wordsState, dropArea as dropAreaState } from "~/recoil";
import { TWord } from '~/content/types';
import { useEffect, useRef, useCallback } from "react";
import { useDragReorder } from "./reorder";

/** styles */
const AreaContainer = styled(motion.div)`
  padding: 10px 7px;
  border-radius: 6px;
  border: dashed 2px ${colors.darkpalegreen};
  margin-bottom: 20px;
`
const Area = styled(motion.div)<StylesProps>`

  /* TODO: make the height static instead! (to prevent layout redraw) with breakpoints */
  min-height: 112px;
  position: relative;
  padding: 8px 6px;
  display: flex;
  flex-wrap: wrap;

  ${respondFrom(
    breakpoints.xl,
    css`
      height: 112px;
      overflow: hidden;
    `
  )}

  &:before {
    content: '';
    ${absoluteFill};
    background-color: #f8f8f8;
    opacity: ${ props => props.isDraggedOver ? 0.8 : (props.hasWords ? 1 : 0.2) };
    border-radius: 6px;

    transition: all ${animation.fast}ms ease;
    transition-property: opacity;
  }
`

const AreaPlaceholder = styled.span<StylesProps>`
  ${absoluteCenter};
  color: ${props => props.isDraggedOver ? colors.darkgreen : colors.darkgreen};
  opacity: 0.71;
  text-align: center;
  width: 100%;

  transition: all ${animation.fast}ms ease;
  transition-property: color;
  font-size: ${dimensions.fontSize.regular}px;

  span {
    font-size: ${dimensions.fontSize.small}px;
    display: block;
    text-align: center;
    line-height: 1.2;
  }
`

interface StylesProps {
  isDraggedOver: boolean;
  hasWords?: boolean;
}
interface DropAreaProps {
  onWordDrag: (word: TWord, info: PanInfo) => void;
  onWordDrop: (word: TWord, info: PanInfo) => void;
  onWordTapQuick: (word: TWord) => void;
  isDraggedOver: boolean;
}
export const DropArea: React.FC<DropAreaProps> = props => {

  const ref = useRef<HTMLDivElement>(null);
  const [words, setWords] = useRecoilState(wordsState);
  const dropArea = useRecoilValue(dropAreaState);
  const setDropArea = useSetRecoilState(dropAreaState);

  const [orderedWords, setOrderedWords, reorderOnDrag] = useDragReorder(words);
  const [saveOrderQueued, setSaveOrderQueued] = useState(false);
  const [wordsUpdateVer, setWordsUpdateVer] = useState(0);

  const updateDimensions = () => {
    if( ref ) {
      const elem = ref.current;
      const newDim = utils.getElemDimensions(elem);
      // TODO: why is dropArea always default?
      if( !isEqual( dropArea, newDim ) ) {  // deep comp!
        setDropArea( newDim ! );
      }
    }
  }

  useEffect(() => {
    setOrderedWords(words);
    setWordsUpdateVer( wordsUpdateVer+1 );
  }, [words]); 

  useEffect(() => {
    updateDimensions()
  }, [/* words */]);    // TODO: use static drop area to not need it!

  useEffect(() => { // will run only once
    // !utils.isSSR && window.addEventListener('resize', utils.debounce(updateDimensions, 100));

    // TODO: clear!
  }, []);

  useEffect(() => {
    if( saveOrderQueued ) {
      setWords( orderedWords );
      setSaveOrderQueued( false );
    }
  }, [orderedWords, saveOrderQueued]);

  
  const onDrag = (word: TWord, info: PanInfo) => {
    const currentIndex = findIndex(orderedWords, (elem) => elem.index === word.index );
    reorderOnDrag(currentIndex, info.offset);
    props.onWordDrag(word, info);
  };

  const onDrop = useCallback((word: TWord, info: PanInfo) => {
    props.onWordDrop(word, info);

    // reorder
    if( word.isSelected ) {
      setTimeout( () => setSaveOrderQueued( true ), 0 );
    }
  }, [words]);


  // const onDrag = useCallback((word, info) => props.onWordDrag(word, info), [props.onWordDrag]);
  // const onDrop = useCallback((word, info) => props.onWordDrop(word, info), [props.onWordDrop]);
  const onTapQuick = useCallback((word) => props.onWordTapQuick(word), [props.onWordTapQuick]);

  return ( 
    <AreaContainer 
      // layout
    >
      <Area 
        // layout
        isDraggedOver={props.isDraggedOver}
        hasWords={words.length > 0}
        ref={ref}
        id="gm-drop-area"
      >
        {
          words.length ? 
            // <AnimatePresence exitBeforeEnter={true}>
            // {
              orderedWords.map( word => 
                  // <motion.span 
                  //   key={`${word.text}${word.key}`} 
                  //   initial={{opacity: 0}}
                  //   animate={{opacity: 1}}
                  //   exit={{opacity: 0}}
                  // >
                    <DraggableWord 
                      key={`${word.text}${word.key}`} 
                      word={word}
                      onDrag={onDrag} 
                      onDrop={onDrop} 
                      onTapQuick={onTapQuick}
                      // onVieportBoxUpdate={onVieportBoxUpdate}
                      index={word.index}
                      updateVer={ wordsUpdateVer }
                    ></DraggableWord> 
                  // </motion.span>
              )
            // }
            // </AnimatePresence> 
            :
            <AreaPlaceholder 
              isDraggedOver={props.isDraggedOver}
              hasWords={words.length > 0}
            >
              { utils.t('Drop pills here') }
              <span>{ utils.t('(you can use maximum 10 pills)') }</span>
              
            </AreaPlaceholder>
        }
      </Area>
    </AreaContainer>
  );
}
 
export default DropArea;