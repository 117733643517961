import { PageProps } from "gatsby";
import * as React from "react";

import SEO from "~/components/layout/Seo";
import MessageCreator from "~/components/index/MessageCreator";

interface IndexPageProps extends PageProps {}
const IndexPage: React.FC<IndexPageProps> = (props) => {

  return (
    <>
      <SEO />
      <MessageCreator />
    </>
  )
}
export default IndexPage;